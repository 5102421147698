import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Strategies`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-easy-peasy/"
            }}>{`Easy-Peasy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-market-vane/"
            }}>{`Market Vane`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: income`}</li>
        <li parentName="ul">{`Type: trend-following strategy w/ macroeconomic cues`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking stocks and T-bills`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly`}</li>
        <li parentName="ul">{`Taxation: 80% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $1,000`}</li>
      </ul>
      <p>{`Nelson Freeburg was a respected and influential researcher who developed many timing models for the stock, bond, and commodity markets. Between 1991 and 2014, he published the `}<em parentName="p">{`Formula Research`}</em>{` newsletter, which was widely appreciated by professional money managers and traders. Freeburg published the `}<em parentName="p">{`PENTAD`}</em>{` strategy in the October 1995 issue of `}<em parentName="p">{`Formula Research`}</em>{`.`}</p>
      <p><em parentName="p">{`PENTAD`}</em>{` aims to participate in all major stock market moves, while at the same time reducing risk and avoiding deep downturns. `}<em parentName="p">{`PENTAD`}</em>{` achieves its objective by combining price indicators with macroeconmic cues. `}</p>
      <p>{`The strategy has low maintenance requirements. While it requires weekly rebalancing, this process is rather simple because it only holds a single ETF.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "#"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "611px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "129.33333333333331%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADSElEQVRIx1XV5Y4lOwwE4PP+DzN/hpmZmZmZeTS++rzyau+RrKTTSbmq7PRpXF1dxfv7e1xcXMTq6mqsra3FwsJCxt7eXpyensbJyUkcHBzEyspKbG1txfb2dhweHuaz+ebmZsB5fX2Nxs3NTfz+/sbLy0uCOmzzzMxMxuLiYqyvryfQ3NxczM/Px9LSUlxeXsbR0VHc398nWP0aNgwPD8fk5GQewmpnZydBlpeXk4X33d3duUeS6enpv0qszc7OxsjISOzv70cDgEMASEZfZqMEGxsbyQhT+wBZY4EzCEkgKSINlPmENsks8Lu9vY2Hh4cEdchhoAD5dnd3F29vbxm88/v6+ooGNqQVCz55dsDGqamplDM0NJTjwMBA9Pf359jR0RGdnZ05RywB6e7r64vBwcF82dTUlIcxlN0ozs/PUwEC4uzsLD07Pj7OAJaAqspU5vKQHPLF7u5ueqXqAIz2q3ABVbICTQ9lrn6S1dr19XUmYjYFZJq3tbWlmp6enpRaHeD56ekpGrziU1dXV75obm5OthhWRVW3vJVEkbSPZ3PeU5eNjQ0AGbBoaWnJNsCQNMyBYl9z7WGu5cTz83P6nZK1hs1AMB0dHc3WAegwFthgJsxrn5iYmIixsbEsJKyUTBpAnW9kPqPJsVbSBDWeMdOjeljVPadkVYLO7Pb29mhtbU1PSjKWqi1pXTd+qbZ1ocJAExBNEviner29vTE+Ph7VTuYKUneW5PpAOFfrCqceDdVE3UFe8IXcf/vNM2laSj8qglFxhD3WkqFDkG0wF1iTbARWPSoJaWzi/b+jQmZRNGM198fHR3x+fmYL+D4KYKxgiYqSZh3I4+NjJgZm/vPz88dDL1XWS0zIYEW9q0PYO0i+wihYsff+bx+WZGyrCAAxrXuqLeo+W+MZuRKU9O/v7z+AHhwCSA4grHwDsRD111CJAZYtpeR/DG02xwwQRgogmbDHISPwuh1axxmS1aCBsixagh9uAQ+B+DcsNqIUOExR/RtWlRMQK+EWyGQTjwBKYo1MhWCDm2G0p+RXQRFoaBeH6g9KQdxn169ugHA77CHRsytorzV7EcmbgqaQCdOSUZ/3GjFjBW/NJacKS2qc18P/AeLIT5Jsu32bAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "formula research",
                "title": "formula research",
                "src": "/static/1d81ddd2490fdfe9e857b9f3d5bc8f1c/36bb5/formula-research.png",
                "srcSet": ["/static/1d81ddd2490fdfe9e857b9f3d5bc8f1c/5a46d/formula-research.png 300w", "/static/1d81ddd2490fdfe9e857b9f3d5bc8f1c/0a47e/formula-research.png 600w", "/static/1d81ddd2490fdfe9e857b9f3d5bc8f1c/36bb5/formula-research.png 611w"],
                "sizes": "(max-width: 611px) 100vw, 611px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.legacy.com/us/obituaries/commercialappeal/name/nelson-freeburg-obituary?id=12464181"
            }}>{`Nelson Freeburg Obituary`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubsV2/Freeburg_PENTAD.cs"
            }}>{`Source code`}</a></li>
        </ul>
      </Aside>
      <p><em parentName="p">{`PENTAD`}</em>{` was only published as part of the newsletter, and a DVD course. Both are no longer officially available. Therefore, we summarize the rules here:`}</p>
      <ul>
        <li parentName="ul">{`For each input, calculate a moving average. The table below specifies the type and period to use.`}</li>
        <li parentName="ul">{`Inputs following the crossover rule (S&P 500, adv/ decl Line, bond index) go bullish, when the index climbs above the moving average plus the buy filter. They go bearish, when the index falls below the moving average minus the sell filter.`}</li>
        <li parentName="ul">{`Inputs following the slope rule (transports index, utilitis index) go bullish, when the moving average climb more than the buy filter from a previous low. They go bearish, when the moving average falls more than the sell filter from a previous high.`}</li>
        <li parentName="ul">{`The strategy goes bullish, when all five inputs show bullish signals. It goes bearish, when only three or less inputs remain bullish.`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Index`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`MA Type`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`MA Length`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Signal Mode`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Buy Filter`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Sell Filter`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`S&P 500`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`WMA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`65 wks`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Crossover`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`3%`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`NYSE Weekly Adv/Dec Line`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`WMA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`14 wks`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Crossover`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0.5%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2%`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Dow Jones Transports`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`SMA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`25 weeks`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Slope`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0.5%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2.5%`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Dow Jones Utilities`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`SMA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`27 weeks`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Slope`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`0%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`3%`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Dow Jones 20 Bonds`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`WMA`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`38 weeks`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Crossover`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`1%`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`2%`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`Our implementation is as close to Freeburg's publication as possible, while at the same time maintianing TuringTrader's conventions for other strategies. Here are the changes we made:`}</p>
      <ul>
        <li parentName="ul">{`We fixed an issue with the Advance/ Decline Line. Because this line accumulates the daily advances minus declines, its absolute value depends on the start date. Consequently, any percentage of that overall value is meaningless. We fixed the issue by replacing the NYSE index with the Russell 3000 index and using a hysteresis in absolute terms.`}</li>
        <li parentName="ul">{`The Dow Jones 20 Bonds index is discontinued. We replaced it with an index tracking the aggregate bond market.`}</li>
        <li parentName="ul">{`Freeburg did not consider any trading friction. Our backtest assumes TuringTrader's standard amount to cover for fees and slippage.`}</li>
      </ul>
      <h2>{`Diversification`}</h2>
      <p>{`The strategy only invests in a single ETF, tracking either the S&P 500, or T-Bills as a risk-off asset. Consequently, `}<em parentName="p">{`PENTAD`}</em>{`'s can only benefit from a reduction in individual company risk, while in the short term assuming the same risk as the stock market. However, because this is a tactical strategy, there are some additional befits from `}<em parentName="p">{`serial diversification`}</em>{`.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Compared to the 60/40 benchmark, the strategy provides some protection against prolonged downturns. As such, the strategy has handled the 2008 recession nicely. However, because least two of `}<em parentName="p">{`PENTAD`}</em>{`'s five indicators are rather slow, Freeburg's strategy fails to handle fast-paced events, leading to steep losses in early 2020.`}</p>
      <p>{`The Monte Carlo simulation shows that `}<em parentName="p">{`PENTAD`}</em>{` provides only a thin edge over the bassive benchmark. However, the expected drawdowns are slightly lower, leading to a faster recovery.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the strategy toggles its holdings, it triggers taxable events. Investors need to be prepared that assets are rarely held long enough to qualify for long-term treatment of capital gains. Therefore, `}<em parentName="p">{`PENTAD`}</em>{` works best in tax-deferred accounts.`}</p>
      <p>{`Because the strategy only holds a single ETF at a time, an account of $1,000 or more is sufficient to trade Freeburg's model.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, October 2023`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      